import {
  UserOperationsApi,
  AuthApi,
  SsoLoginDto,
  VerifySsoOtpDto,
  ResendSsoOtpDto,
} from '@healthhub/api-lib';
import Cookies from 'js-cookie';

import HealthHubApiClient from '@mwell-healthhub/commons/api/api-client';
import { SSO_REFRESH_TOKEN, SSO_TOKEN } from '@mwell-healthhub/commons/constants';

const usersApi = HealthHubApiClient.use(UserOperationsApi);
const authApi = HealthHubApiClient.use(AuthApi);

export async function getCurrentUserOperation() {
  try {
    const response = await usersApi.userOperationControllerFindCurrentUser();

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch current user operation');
  }
}

export async function ssoLogin(dto: SsoLoginDto) {
  const response = await authApi.authControllerLogin(dto);

  return response.data;
}

export async function ssoAuthenticate(accessToken: SsoLoginDto, loginSessionToken: string) {
  const response = await authApi.authControllerAuthenticate(loginSessionToken, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return response.data;
}

export async function ssoVerifyOtp(dto: VerifySsoOtpDto, accessToken: string) {
  const response = await authApi.authControllerVerifyOtp(dto, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return response.data;
}

export async function ssoResendOtpEmail(dto: ResendSsoOtpDto, accessToken: string) {
  const response = await authApi.authControllerResendOtp(dto, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return response.data;
}

export async function ssoLogout() {
  const refreshToken = Cookies.get(SSO_REFRESH_TOKEN);
  const accessToken = Cookies.get(SSO_TOKEN);

  const response = await authApi.authControllerLogout(
    { refresh_token: refreshToken },
    { headers: { Authorization: `Bearer ${accessToken}` } },
  );

  Cookies.remove(SSO_REFRESH_TOKEN);
  Cookies.remove(SSO_TOKEN);

  return response.data;
}
