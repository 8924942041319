import { SHOW_SSO } from '@mwell-healthhub/commons';

import { AuthProviderFirebase } from './auth-provider-firebase';
import { AuthProviderSSO } from './auth-provider-sso';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider(props: Props) {
  if (SHOW_SSO) {
    return <AuthProviderSSO defaultUser={null}>{props.children}</AuthProviderSSO>;
  } else {
    return <AuthProviderFirebase defaultUser={null}>{props.children}</AuthProviderFirebase>;
  }
}
