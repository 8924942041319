import {
  CreatePromotionDto,
  Promotion,
  PromotionApi,
  PromotionWithProvidersDto,
  UpdatePromotionDto,
} from '@healthhub/api-lib';

import { CustomError } from '@mwell-healthhub/commons';
import HealthHubApiClient from '@mwell-healthhub/commons/api/api-client';

const promotionApi = HealthHubApiClient.use(PromotionApi);

export async function getPromotionById(id: string): Promise<PromotionWithProvidersDto> {
  try {
    const response = await promotionApi.promotionControllerFindOne(id);
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getAllPromotions(): Promise<Promotion[]> {
  try {
    const response = await promotionApi.promotionControllerFindAll();
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getAllPublishedPromotions(): Promise<PromotionWithProvidersDto[]> {
  try {
    const response = await promotionApi.promotionControllerFindAllPublishedPromotions();
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function createPromotion(createPromotionDto: CreatePromotionDto): Promise<Promotion> {
  try {
    const response = await promotionApi.promotionControllerCreate(createPromotionDto);
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updatePromotion(
  id: string,
  updatePromotionDto: UpdatePromotionDto,
): Promise<Promotion> {
  try {
    const response = await promotionApi.promotionControllerUpdate(id, updatePromotionDto);
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function removePromotion(id: string): Promise<void> {
  try {
    const response = await promotionApi.promotionControllerRemove(id);
    return response.data;
  } catch (error) {
    throw new CustomError(error);
  }
}
