/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CreateUserOperationDto,
  UpdateUserOperationDto,
  UserOperation,
  UserOperationUserAppTypeEnum,
  UserOperationUserTypeEnum,
  UserOperationsApi,
} from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient, { API_BASE_URL } from '@mwell-healthhub/commons/api/api-client';
import { CustomError } from '@mwell-healthhub/commons/errors';

const userOperationsApi = HealthHubApiClient.use(UserOperationsApi);

export async function registerUser(userDetails: CreateUserOperationDto): Promise<any> {
  try {
    const response = await userOperationsApi.userOperationControllerRegisterUser(userDetails);
    const { data } = response as any;

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateUserOperation(
  id: number,
  userDetails: UpdateUserOperationDto,
): Promise<any> {
  try {
    const response = await userOperationsApi.userOperationControllerUpdate(
      id.toString(),
      userDetails,
    );
    const { data } = response as any;

    return data.user;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getUserOperationById(id: string, config?: AxiosRequestConfig) {
  try {
    const { data } = await userOperationsApi.userOperationControllerFindOne(id, config);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getAllOperationUsers(
  appType?: UserOperationUserAppTypeEnum,
  userType?: UserOperationUserTypeEnum,
) {
  try {
    const { data } = await userOperationsApi.userOperationControllerGetAll(userType, appType);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function deactivateOperationUser(id: number) {
  try {
    await userOperationsApi.userOperationControllerDeactivate(id.toString());
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function activateOperationUser(id: number) {
  try {
    await userOperationsApi.userOperationControllerActivate(id.toString());
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getCurrentOperationUser(config: RequestInit): Promise<UserOperation> {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/operation-users/me`, config);
    const data = await response.json();

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}
