/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateUserProviderDto, UpdateUserProviderDto, UserProvidersApi } from '@healthhub/api-lib';
import { AxiosRequestConfig } from 'axios';

import HealthHubApiClient from '@mwell-healthhub/commons/api/api-client';
import { CustomError } from '@mwell-healthhub/commons/errors';

const userProvidersApi = HealthHubApiClient.use(UserProvidersApi);

export async function registerMerchant(userDetails: CreateUserProviderDto): Promise<any> {
  try {
    const response = await userProvidersApi.userProviderControllerRegisterUser(userDetails);
    const { data } = response as any;

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function updateUserProvider(
  id: number,
  userDetails: UpdateUserProviderDto,
): Promise<any> {
  try {
    const response = await userProvidersApi.userProviderControllerUpdate(
      id.toString(),
      userDetails,
    );
    const { data } = response as any;

    return data.user;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getUserProviderById(id: string, config?: AxiosRequestConfig) {
  try {
    const { data } = await userProvidersApi.userProviderControllerFindOne(id, config);

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function getAllProviderUsers() {
  try {
    const { data } = await userProvidersApi.userProviderControllerGetAll();

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function deactivateProviderUser(id: number) {
  try {
    await userProvidersApi.userProviderControllerDeactivate(id.toString());
  } catch (error) {
    throw new CustomError(error);
  }
}

export async function activateProviderUser(id: number) {
  try {
    await userProvidersApi.userProviderControllerActivate(id.toString());
  } catch (error) {
    throw new CustomError(error);
  }
}
