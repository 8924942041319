import { createContext } from 'react';

import { AbilityTuple, MatchConditions, PureAbility } from '@casl/ability';
import { createContextualCan, useAbility } from '@casl/react';

import { Action, Subject } from '@mwell-healthhub/commons';

type AppAbility = PureAbility<AbilityTuple, MatchConditions>;

export function formatToAppAbilities(ability: AppAbility) {
  return {
    canUpdateUser: ability.can(Action.UPDATE, Subject.USER),
    canCreateUser: ability.can(Action.CREATE, Subject.USER),
    canViewProviderUsers: ability.can(Action.VIEW, Subject.USER_PROVIDERS),
    canCreateProviderUser: ability.can(Action.CREATE, Subject.USER_PROVIDER),
    canUpdateProviderUser: ability.can(Action.UPDATE, Subject.USER_PROVIDER),
    canGiveProviderUserAccess: ability.can(Action.GIVE_ACCESS, Subject.USER_PROVIDER),
    canRemoveProviderUserAccess: ability.can(Action.REMOVE_ACCESS, Subject.USER_PROVIDER),
    canUpdateProvider: ability.can(Action.UPDATE, Subject.PROVIDER),
    canCreateProvider: ability.can(Action.CREATE, Subject.PROVIDER),
    canDeleteProvider: ability.can(Action.DELETE, Subject.PROVIDER),
    canViewProvider: ability.can(Action.VIEW, Subject.PROVIDER),
    canViewBranches: ability.can(Action.VIEW, Subject.BRANCHES),
    canViewAppointments: ability.can(Action.VIEW, Subject.APPOINTMENTS),
    canViewClients: ability.can(Action.VIEW, Subject.PROVIDER_CLIENT_PROFILES),
    canExportClients: ability.can(Action.EXPORT, Subject.CLIENT),
    canViewServices: ability.can(Action.VIEW, Subject.PROVIDER_SERVICES),
    canViewSchedules: ability.can(Action.VIEW, Subject.SCHEDULES),
    canViewTags: ability.can(Action.VIEW, Subject.PROVIDER_TAG_CATEGORIES),
    canViewShareLink: ability.can(Action.VIEW, Subject.SHARE_LINK),
    canManageSettings: ability.can(Action.MANAGE, Subject.SETTINGS),
    canViewReferrals: ability.can(Action.VIEW, Subject.REFERRAL),
  };
}

export const AbilityContext = createContext<any>([]);

export const Can = createContextualCan(AbilityContext.Consumer);

export function useAbilityContext() {
  const abilities = useAbility(AbilityContext);

  return formatToAppAbilities(abilities);
}
