import { AnalyticsApi, KeyMetricsDto } from '@healthhub/api-lib';

import { CustomError } from '@mwell-healthhub/commons';
import HealthHubApiClient from '@mwell-healthhub/commons/api/api-client';
const analyticsApi = HealthHubApiClient.use(AnalyticsApi);

export async function getKeyMetrics(date?: string): Promise<KeyMetricsDto> {
  try {
    const config = date ? { params: { date } } : undefined;
    const response = await analyticsApi.analyticsControllerGetKeyMetrics(config);
    const { data } = response;

    return data;
  } catch (error) {
    throw new CustomError(error);
  }
}
