'use client';

import * as React from 'react';

import { PureAbility } from '@casl/ability';
import { IdTokenResult, onIdTokenChanged, User } from 'firebase/auth';
import { filterStandardClaims } from 'next-firebase-auth-edge/lib/auth/claims';

import { formatStringToMD5, setAnalyticsUser } from '@mwell-healthhub/commons';
import { useFirebaseAuth } from '@mwell-healthhub/commons/auth/firebase';

import { AbilityContext, AuthContext } from '../contexts';
import { getCurrentUserOperation } from '../services';
import { UserWithOperation } from '../types';

export interface AuthProviderProps {
  defaultUser: UserWithOperation | null;
  children: React.ReactNode;
}

function toUser(user: User, idTokenResult: IdTokenResult): UserWithOperation {
  return {
    ...user,
    customClaims: filterStandardClaims(idTokenResult.claims),
  } as unknown as UserWithOperation;
}

export const AuthProviderFirebase: React.FunctionComponent<AuthProviderProps> = ({
  defaultUser,
  children,
}) => {
  const { getFirebaseAuth } = useFirebaseAuth();
  const [user, setUser] = React.useState(defaultUser);

  const handleIdTokenChanged = async (firebaseUser: User | null) => {
    if (!firebaseUser) {
      setUser(null);

      return;
    }

    const idTokenResult = await firebaseUser.getIdTokenResult();
    let userOperation = null;

    try {
      userOperation = await getCurrentUserOperation();
    } catch (error) {
      console.error(error);
      setUser(null);

      return;
    }

    setUser({
      ...toUser(firebaseUser, idTokenResult),
      userOperation,
    });

    setAnalyticsUser(formatStringToMD5(userOperation.email));
  };

  const registerChangeListener = async () => {
    const auth = getFirebaseAuth();

    return onIdTokenChanged(auth, handleIdTokenChanged);
  };

  React.useEffect(() => {
    const unsubscribePromise = registerChangeListener();

    return () => {
      unsubscribePromise.then((unsubscribe) => unsubscribe());
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
      }}
    >
      <AbilityContext.Provider value={new PureAbility(user?.userOperation.userAbilities)}>
        {children}
      </AbilityContext.Provider>
    </AuthContext.Provider>
  );
};
