import { setTraceId } from '@healthhub/api-client';
import { UserVerificationApi, VerifyOtpDto } from '@healthhub/api-lib';
import Cookies from 'js-cookie';

import { OTP_TOKEN } from '@mwell-healthhub/commons';
import HealthHubApiClient, { API_BASE_URL } from '@mwell-healthhub/commons/api/api-client';

const userVerificationService = HealthHubApiClient.use(UserVerificationApi);

export async function sendEmailOtp(accessToken?: string) {
  const config = accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {};

  const { data } = await userVerificationService.userVerificationControllerSendEmailOtp(config);

  return data;
}

export async function verifyEmailOtp(verifyOtpDto: VerifyOtpDto) {
  const { data } =
    await userVerificationService.userVerificationControllerVerifyEmailOtp(verifyOtpDto);

  return data;
}

// Work around since axios does not work inside middleware
export async function checkEmailOtpVerificationValidity(accessToken: string, otpToken: string) {
  try {
    const url = new URL('/api/v1/user-verification/email/otp/verification/validity', API_BASE_URL);
    const result = await fetch(url, {
      ...(setTraceId({
        method: 'POST',
        headers: {
          Authorization: `Basic ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }) as RequestInit),
      body: JSON.stringify({
        otpToken,
      }),
    });

    await result.json();

    return true;
  } catch (e) {
    console.error(e);

    return false;
  }
}

export async function checkEmailOtpVerificationValidityWithCredentials(otpToken: string) {
  try {
    const { data } =
      await userVerificationService.userVerificationControllerCheckEmailOtpVerificationValidity({
        otpToken,
      });

    return data;
  } catch (e) {
    Cookies.remove(OTP_TOKEN);

    console.error(e);

    return null;
  }
}
