'use client';

import { createContext, useContext } from 'react';

import { UserWithOperation } from '../types/auth.types';

export interface AuthContextValue {
  user: UserWithOperation | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthContext = createContext<AuthContextValue>({
  user: null,
});

export const useAuth = () => useContext(AuthContext);
