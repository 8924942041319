'use client';

import * as React from 'react';

import { PureAbility } from '@casl/ability';

import { formatStringToMD5, setAnalyticsUser } from '@mwell-healthhub/commons';

import { AbilityContext, AuthContext } from '../contexts';
import { getCurrentUserOperation } from '../services';
import { UserWithOperation } from '../types';

export interface AuthProviderProps {
  defaultUser: UserWithOperation | null;
  children: React.ReactNode;
}

export const AuthProviderSSO: React.FunctionComponent<AuthProviderProps> = ({
  defaultUser,
  children,
}) => {
  const [user, setUser] = React.useState<UserWithOperation | null>(defaultUser);

  const checkAuthStatus = React.useCallback(async () => {
    try {
      const userOperation = await getCurrentUserOperation();

      const newUser: UserWithOperation = {
        email: userOperation.email,
        userOperation,
      };

      setUser(newUser);
      setAnalyticsUser(formatStringToMD5(userOperation.email));
    } catch (error) {
      console.error(error);
      setUser(null);
      setAnalyticsUser('');
    }
  }, []);

  React.useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  return (
    <AuthContext.Provider value={{ user }}>
      <AbilityContext.Provider value={new PureAbility(user?.userOperation.userAbilities)}>
        {children}
      </AbilityContext.Provider>
    </AuthContext.Provider>
  );
};
