import { NextComponentType, NextPageContext } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import NextTopLoader from 'nextjs-toploader';
import { ToastContainer } from 'react-toastify';

import { ErrorProvider, QueryClientProvider } from '@mwell-healthhub/commons';

import { AuthProvider } from '../auth/auth-provider';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import { HEALTHHUB_APP_NAME_FULL } from '../constants/app';

type GetLayoutFunction = (page: JSX.Element) => JSX.Element;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageWithLayout<P = NonNullable<unknown>> = NextComponentType<NextPageContext, any, P> & {
  getLayout?: GetLayoutFunction;
};

type Props = {
  Component: PageWithLayout;
  pageProps: AppProps['pageProps'];
};

function CustomApp(props: Props) {
  const { Component, pageProps } = props;
  const getLayout: GetLayoutFunction = Component.getLayout || ((page) => page);
  const activeHeading = HEALTHHUB_APP_NAME_FULL;

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="analytics-script" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
        `}
      </Script>
      <Script id="moengage-analytics" strategy="afterInteractive">
        {`
          const moeDataCenter = '${process.env.NEXT_PUBLIC_MOENGAGE_DATA_CENTER ?? ''}';
          const moeAppID = '${process.env.NEXT_PUBLIC_MOENGAGE_APP_ID ?? ''}';

          !function(e,n,i,t,a,r,o,d){if(!moeDataCenter||!moeDataCenter.match(/^dc_[0-9]+$/gm))return console.error("Data center has not been passed correctly. Please follow the SDK installation instruction carefully.");var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","update_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/release/"+moeDataCenter+"/moe_webSdk.min.latest.js","Moengage");

          Moengage = moe({
            app_id: moeAppID,
            debug_logs: Number(${process.env.NEXT_PUBLIC_MOENGAGE_DEBUG_LOGS ?? 0})
          });
        `}
      </Script>
      <Head>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static-assets/favicon-32px.png?v=2"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static-assets/favicon-16px.png?v=2"
        />
        <title>{activeHeading}</title>
      </Head>
      <main>
        <ErrorProvider>
          <AuthProvider>
            <QueryClientProvider>
              <NextTopLoader showSpinner={false} />
              {getLayout(<Component {...pageProps} />)}
              <ToastContainer hideProgressBar />
            </QueryClientProvider>
          </AuthProvider>
        </ErrorProvider>
      </main>
    </>
  );
}

export default CustomApp;
